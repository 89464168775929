@charset 'UTF-8';

@use '../global/' as *;

// 1文字づつ表示
.textIn {
  display: flex;
  overflow: hidden;
  span {
    transform: translateY(2em);
    opacity: 0;
  }
  &.is-visible {
    &:not(.delay) {
      span {
        $delay: 0.1;
        @for $i from 1 through 20 {
          &:nth-child(#{$i}) {
            animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) ($delay * $i + s) forwards;
          }
        }
      }
    }
    &.delay {
      span {
        $delay: 0.1;
        @for $i from 1 through 20 {
          &:nth-child(#{$i}) {
            animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) (0.5 + $delay * $i + s) forwards;
          }
        }
      }
    }
  }
}

// フェードイン
.fadeIn {
  opacity: 0;
  &.is-visible {
    &:not(.delay) {
      animation: fadeIn 1.3s cubic-bezier(0.33, 1, 0.68, 1) forwards;
    }
    &.delay {
      animation: fadeIn 1.3s cubic-bezier(0.33, 1, 0.68, 1) 0.3s forwards;
    }
  }
}
.fadeUp {
  opacity: 0;
  &.is-visible {
    &:not(.delay) {
      animation: fadeUp 1.3s cubic-bezier(0.33, 1, 0.68, 1) forwards;
    }
    &.delay {
      animation: fadeUp 1.3s cubic-bezier(0.33, 1, 0.68, 1) 0.3s forwards;
    }
  }
}

// shutter
.shutterIn {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $primary;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    background-color: $base;
    width: 0;
    height: 1px;
  }
  &.is-visible {
    animation: byeShutter 1.8s forwards;
    &::before {
      animation: shutterOpen 1.8s forwards;
    }
  }
}

// テキストアニメーション
@keyframes textIn {
  0% {
    transform: translateY(2em);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

// フェード
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeUp {
  0% {
    transform: translateY(3rem);
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

// シャッタースライド
@keyframes byeShutter {
  70% {
    opacity: 1;
  }
  99% {
    z-index: 0;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}
@keyframes shutterOpen {
  0% {
    width: 0;
    height: 1px;
    background-color: $base;
  }
  40% {
    width: 100%;
    height: 1px;
  }
  90% {
    width: 100%;
    height: 100%;
  }
  100% {
    width: 100%;
    height: 100%;
    background-color: $primary;
  }
}

// ズーム
@keyframes zoomIn {
  100% {
    transform: scale(1.1);
  }
}
@keyframes zoomOut {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
