@charset 'UTF-8';

$icon-font-family: 'potaicon' !default;
$icon-font-path: '/assets/fonts/icon' !default;

$c-phone: '\e936';
$flow-arrow: '\e91f';
$c-search: '\e920';
$c-movie: '\e921';
$c-etc: '\e922';
$c-close: '\e932';
$c-chevron-right: '\e933';
$c-chevron-left: '\e934';
$c-book: '\e935';
$headset: '\e91e';
$tree: '\e923';
$search: '\e924';
$quiz: '\e925';
$map: '\e926';
$map-pin: '\e927';
$home: '\e928';
$calendar: '\e929';
$datetime: '\e92d';
$circle-phone: '\e92e';
$document: '\e92f';
$pdf: '\e930';
$warning-outline: '\e931';
$caution: '\e900';
$caution-outline: '\e901';
$warning: '\e902';
$phone: '\e903';
$mail: '\e904';
$plus: '\e905';
$add: '\e906';
$add-outline: '\e907';
$clear: '\e908';
$minus: '\e909';
$remove: '\e90a';
$remove-outline: '\e90b';
$chevron-down: '\e90c';
$chevron-left: '\e90d';
$chevron-right: '\e90e';
$chevron-up: '\e90f';
$smart-phone: '\e910';
$radio: '\e911';
$arrow-left: '\e912';
$caret-down: '\e913';
$caret-up: '\e914';
$arrow-right: '\e915';
$arrow-up: '\e916';
$arrow-down: '\e917';
$caret-left: '\e918';
$caret-right: '\e919';
$arrow-back: '\e92b';
$arrow-forward: '\e92c';
$checkbox-checked: '\e91a';
$checkbox: '\e91b';
$radio-checked: '\e91c';
$open-new-window: '\e91d';
$help: '\e94e';
$delete: '\e92a';
