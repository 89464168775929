@charset 'UTF-8';

@use '../global/' as *;

// variables
$tab-button: '.js-btn';
$tab-current-class-name: '.js-tab-current';
$tab-current-view-class-name: '.js-tab-current-view';

.tab-wrapper {
  width: 100%;
}

.tab-label-wrapper {
  overflow: auto;
}
.tab-label {
  display: flex;
  gap: 0.4rem;
  margin-bottom: 1.3rem;
  margin-left: 2rem;
  margin-right: 2rem;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
  width: max-content;
  @include media(md) {
    gap: 0.8rem;
  }
  @include media(lg) {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

.tab-label-item {
  width: 100%;
  padding: 0.6rem 1rem;
  text-align: center;
  border: 1px solid $base;
  border-radius: 4rem;
  font-size: 1.3rem;
  transition: all $base-transition;
  @include media(lg) {
    padding: 0.6rem 1.2rem;
  }
  @media (hover: hover) {
    &:hover {
      color: $white;
      background-color: $base;
      border-color: $base;
    }
  }

  &#{$tab-current-class-name} {
    color: $white;
    background-color: $base;
    border-color: $base;
  }
}

.tab-contents {
  border: 1px solid $base;
  border-radius: $base-border-radius;
  padding: 2rem;
  @include media(lg) {
    padding: 4rem;
  }
}

.tab-contents-item {
  display: none;

  &#{$tab-current-view-class-name} {
    display: block;
    animation: show 0.4s linear 0s;
  }
}
