@charset 'UTF-8';
@use '../global/' as *;

.timeline {
  &-item {
    display: flex;
    &:not(:last-child) {
      margin-bottom: 35px;
      @include media(lg) {
        margin-bottom: 37px;
      }
    }
    &:last-child {
      .timeline {
        &-time {
          &::before {
            display: none;
          }
        }
      }
    }
  }
  &-time {
    margin: 0 12px 0 0;
    padding: 0 20px 0 0;
    min-width: 76px;
    position: relative;
    font-family: $base-font-family-en;
    font-size: 1.5rem;
    box-sizing: border-box;
    @include media(lg) {
      margin-right: 31px;
      font-size: 1.8rem;
      min-width: 102px;
    }
    &::after,
    &::before {
      position: absolute;
      right: -4px;
      top: 7px;
      content: '';
      @include media(lg) {
        top: 9px;
      }
    }
    &::before {
      width: 1px;
      right: 7px;
      top: 23px;
      bottom: -42px;
      background-color: #e3e3e3;
      @include media(lg) {
        right: 7px;
        bottom: -48px;
      }
    }
    &::after {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      box-sizing: border-box;
      border: 6px solid $primary-container;
      background-color: $primary;
    }
  }
  &-inner {
    flex: 1;
    @include media(lg) {
      margin-top: 2px;
    }
  }
  &-title {
    color: $base;
    margin: 0 0 9px;
    padding: 0;
    position: static;
    font-size: 1.4rem;
    border: none;
    border-radius: 0;
    @include media(lg) {
      font-size: 1.8rem;
    }
    &::before {
      display: none;
    }
  }
  &-content {
    margin: 0;
    font-size: 1.4rem;
  }
}
