@charset 'UTF-8';
@use 'sass:math';
@use './global/' as sys;
@use '../var/' as g;

$recruit-border: solid 1px g.$border-color;

.recruit-list {
  width: 100%;
  border: $recruit-border;
  li {
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      border-bottom: $recruit-border;
    }
    @include sys.media(md) {
      flex-direction: row;
    }
  }
}

.jobs-heading {
  background-color: g.$primary-container;
  border-bottom: $recruit-border;
  padding: 2rem;
  text-align: left;
  @include sys.media(md) {
    border-bottom: none;
    border-right: $recruit-border;
    display: flex;
    align-items: center;
    flex: 0 0 30%;
  }
  .urgent {
    color: g.$pink;
  }
}

.recruit-btn-wrapper {
  width: 100%;
}

.recruit-btn-container {
  padding: 2rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  flex: 1;
  .btn-column {
    flex: 0 0 32%;
  }
}

.btn-recruit {
  height: 100%;
  background-color: g.$secondary;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: g.$base-border-radius;
  color: g.$white;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 1rem;
  position: relative;
  text-align: center;
  transition: all g.$base-transition;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (hover: hover) {
    &:hover {
      box-shadow: none;
      opacity: 0.5;
    }
  }
  .kubun,
  .work-style {
    @include sys.media(md) {
      margin: 0 0.2rem;
      font-size: 1.8rem;
    }
  }

  .urgent,
  .not-recruiting-word {
    font-size: 1.3rem;
    background-color: g.$white;
    padding: 0 0.6rem;
    border-radius: 4px;
    margin-top: 0.4rem;
    @include sys.media(md) {
      font-size: 1.4rem;
    }
  }
  .urgent {
    @include sys.media(md) {
      margin: 0.4rem 3rem 0;
    }
  }
  .not-recruiting-word {
    @include sys.media(md) {
      margin: 0.4rem 2rem 0;
    }
  }

  // 新卒
  &.shin {
    background-color: sys.$recruit-shin-color;
    color: g.$white;
    .urgent {
      color: sys.$recruit-shin-color;
    }
  }

  // 中途
  &.chu {
    background-color: sys.$recruit-chu-color;
    border-color: sys.$recruit-chu-color;
    color: g.$white;
    .urgent {
      color: sys.$recruit-chu-color;
    }
  }

  // パート
  &.part {
    background-color: sys.$recruit-part-color;
    border-color: sys.$recruit-part-color;
    color: g.$white;
    .urgent {
      color: sys.$recruit-part-color;
    }
  }

  // 外部サイトリンク
  &.external-site-url {
    position: relative;
    &::after {
      content: g.$open-new-window;
      color: g.$white;
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 0.5em;
      transform: translateY(-50%);
      width: 0.875em;
      height: 0.875em;
      @include sys.media(md) {
        right: 1em;
        width: 1em;
        height: 1em;
      }
    }
  }

  // 募集していません
  &.disabled {
    background-color: g.$gray-60;
    box-shadow: none;
    color: g.$base;
    display: block;
    pointer-events: none;
    opacity: 0.3;
  }
}

.btn-recruit-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include sys.media(md) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

// 募集要領

.recruit-detail-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;

  & + & {
    margin-top: 4rem;
  }

  a {
    color: g.$secondary;
    @media (hover: hover) {
      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.recruit-heading {
  font-size: g.$h2-font-size;
  font-weight: normal;
  margin-bottom: g.$h2-margin-bottom;
  padding: 1.8rem 2rem;
  border-top: 1px solid g.$border-color;
  border-bottom: 1px solid g.$border-color;
  &:not(:first-child) {
    margin-top: g.$h2-margin-top;
  }
  @include sys.media(lg) {
    font-size: 2.4rem;
    margin-bottom: 3.2rem;
    padding: 2.4rem 0;
  }
}

.recruit-detail-list {
  $border: 1px solid g.$border-color;
  display: block;
  width: 100%;
  padding: 0 2rem;
  @include sys.media(md) {
    border-collapse: collapse;
    display: table;
  }
  @include sys.media(lg) {
    padding: 0;
  }

  li {
    border: $border;
    display: block;
    margin-bottom: 2rem;

    @include sys.media(md) {
      display: table-row;
      margin-bottom: 0;
      &:nth-of-type(n + 2) {
        border-top: none;
      }
    }

    .detail-head {
      background-color: g.$primary-container;
      border-bottom: $border;
      display: block;
      padding: 1.4rem 1.2rem;
      @include sys.media(md) {
        width: 30%;
        border-right: $border;
        display: table-cell;
        vertical-align: middle;
        white-space: nowrap;
      }
    }

    .detail-content {
      display: block;
      padding: 1.4rem 1.2rem;
      @include sys.media(md) {
        display: table-cell;
      }
    }
  }
}

.form-btn-wrapper {
  margin-top: 4rem;
}

.form-btn-container {
  display: flex;
  justify-content: center;
}

.form-btn-column {
  flex: 0 0 auto;
}

.btn-proceed-form {
  background-color: g.$primary;
  border: 1px solid g.$primary;
  border-radius: g.$base-border-radius;
  color: g.$white;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: g.$btn-font-size-normal;
  text-decoration: none;
  padding: 1em 1em 1em 2em;
  &::after {
    content: g.$arrow-right;
    font-family: g.$icon-font-family;
    color: g.$white;
    margin-left: 1em;
    transition: transform g.$base-transition;
  }
  @media (hover: hover) {
    &:hover {
      background-color: g.$white;
      color: g.$primary;
      &::after {
        color: g.$primary;
        transform: translateX(6px);
      }
    }
  }
}
