@charset 'UTF-8';

@use './color' as *;

// font size
$btn-font-size-normal: 1.6rem !default;
$btn-font-size-small: $btn-font-size-normal * 0.8125 !default;
$btn-font-size-large: $btn-font-size-normal * 1.25 !default;

$btn-font-sizes: (
  'small': $btn-font-size-small,
  'normal': $btn-font-size-normal,
  'large': $btn-font-size-large,
) !default;

// border 太さ
$btn-border-width: 1px !default;

// padding 左右
$btn-x-padding-small: 0.6em !default;
$btn-x-padding-normal: 0.8em !default;
$btn-x-padding-large: 1.2em !default;

$btn-x-paddings: (
  'small': $btn-x-padding-small,
  'normal': $btn-x-padding-normal,
  'large': $btn-x-padding-large,
) !default;

// padding 上下
$btn-y-padding-small: 0.6em !default;
$btn-y-padding-normal: 0.8em !default;
$btn-y-padding-large: 1.2em !default;

$btn-y-paddings: (
  'small': $btn-y-padding-small,
  'normal': $btn-y-padding-normal,
  'large': $btn-y-padding-large,
) !default;

// background color
$btn-background-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'tertiary': $tertiary,
) !default;

$btn-hover-background-colors: (
  'primary': rgba($primary, 0.5),
  'secondary': rgba($secondary, 0.5),
  'tertiary': rgba($tertiary, 0.5),
) !default;

$btn-outline-hover-background-colors: (
  'primary': $primary-container,
  'secondary': $secondary-container,
  'tertiary': $tertiary-container,
) !default;

// text color
$btn-colors: (
  'primary': $base,
  'secondary': $base,
  'tertiary': $base,
) !default;

$btn-hover-colors: (
  'primary': $base,
  'secondary': $base,
  'tertiary': $base,
) !default;
