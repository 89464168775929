@charset "UTF-8";

@use '../global/' as *;

.sidebar {
  margin-top: 8rem;
  padding-top: 3rem;
  border-top: 1px solid $border-color;
  @include media(lg) {
    width: 20%;
    margin-top: 0;
    padding-top: 0;
    border: none;
  }
}

.sidebar-title {
  margin-bottom: 0;
  padding: 0 0 1.6rem;
  border-bottom: 1px solid $base;
  font-size: 1.6rem;
  font-weight: bold;
  @include media(md) {
    @include makeContainer;
  }
  @include media(lg) {
    padding-left: 0;
    padding-right: 0;
  }
}

.sidebar-list {
  margin-bottom: 4rem;
  font-size: 1.3rem;
  &__item {
    &--second {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      a {
        width: 100%;
        padding-right: 1rem;
        flex: 1;
      }
      i {
        font-size: 2rem;
        cursor: pointer;
      }
    }
    &.active {
      i::after {
        content: $minus;
        font-family: $icon-font-family;
      }
    }
  }
  &__link {
    display: block;
    padding: 1rem 0.6rem;
    &.current {
      color: $primary-40;
    }
    @media (hover: hover) {
      &:hover {
        color: $base;
        background-color: $primary-container;
      }
    }
  }
}

.sidebar-second-list {
  display: none;
  width: 100%;
  text-indent: 1em;
  border-left: 1px solid $border-color;
}
