@charset 'UTF-8';
@use './global/' as sys;
@use '../var/' as g;

.caution-news-list {
  width: 100%;
  flex: 1;
}

.caution-news-container {
  transition: color g.$base-transition;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @include sys.media(lg) {
    padding: 0.1em 0;
    flex-wrap: nowrap;
  }
  & + & {
    margin-top: 1.8rem;
    @include sys.media(lg) {
      margin-top: 2.2rem;
    }
  }

  .news-body {
    width: 100%;
  }

  .news-anchor {
    display: block;
  }

  .date {
    font-size: 1.2rem;
    letter-spacing: 0.1em;
    color: g.$base-40;
    margin-bottom: 0.5rem;
    @include sys.media(lg) {
      width: 11.2rem;
      font-size: 1.4rem;
      margin-bottom: 0;
    }
  }

  .category {
    @include sys.media(lg) {
      padding: 2.4rem 1rem;
    }

    .category-label {
      background-color: g.$gray-container;
      border-radius: g.$base-border-radius;
      color: g.$base;
      display: block;
      font-size: 1rem;
      padding: 0.8rem 1.6rem;
      text-align: center;
      white-space: nowrap;
      width: 100%;
      @include sys.media(lg) {
        min-width: 7em;
      }
    }
  }
  .title {
    @include sys.media(lg) {
      width: calc(100% - 11.2rem);
    }
    a {
      display: block;
      @media (hover: hover) {
        &:hover {
          color: g.$tertiary;
        }
      }
    }
  }
}
