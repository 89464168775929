@charset 'UTF-8';

@use '../global/' as *;

.btn {
  min-width: 180px;
  background-color: $secondary;
  border: $btn-border-width solid transparent;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-size: $btn-font-size-normal;
  font-weight: normal;
  text-align: center;
  -webkit-appearance: none;
  text-decoration: none;
  transition: opacity $base-transition, color $base-transition, background-color $base-transition;
  vertical-align: middle;
  white-space: nowrap;
  padding: $btn-y-padding-normal $btn-x-padding-normal;
  line-height: 1;
  border-radius: 3rem;

  // Sizes
  @each $name, $value in $btn-font-sizes {
    &.#{$name} {
      font-size: $value;
      padding: map-get($btn-y-paddings, $name) map-get($btn-x-paddings, $name);
    }
  }

  // hover
  @media (hover: hover) {
    &:hover {
      opacity: 1;
      background-color: lighten($secondary, 7.5%);
      color: $white;
    }
  }

  // Colors variation
  @each $name, $value in $btn-background-colors {
    &.#{$name} {
      background-color: $value;
      color: map-get($btn-colors, $name);
      @media (hover: hover) {
        &:hover {
          opacity: 1;
          background: map-get($btn-hover-background-colors, $name);
          color: map-get($btn-hover-colors, $name);
        }
      }
    }
  }

  // Base
  &.base {
    min-width: 100%;
    padding: $btn-y-padding-large $btn-x-padding-large;
    @include media(md) {
      min-width: 248px;
    }
  }

  // Outline
  &.outline {
    background-color: $white;
    border: $btn-border-width solid $base;
    color: $base;
    transition: all $base-transition;
    // Outline hover
    @media (hover: hover) {
      &:hover {
        opacity: 1;
        background-color: $primary-container;
      }
    }
    // Outline colors
    @each $name, $value in $btn-background-colors {
      &.#{$name} {
        border: $btn-border-width solid $value;
        color: map-get($all-colors, $name);
      }
    }
  }

  // Expanded
  &.expanded {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  &-square {
    width: 100%;
    height: 100%;
    padding: 2rem 4rem 2rem 2rem;
    border: 1px solid $base;
    border-radius: 6px;
    display: inline-block;
    position: relative;
    background-color: $white;
    display: flex;
    align-items: center;
    &::first-line {
      line-height: 1;
    }
    &::after {
      content: $chevron-right;
      width: 1em;
      position: absolute;
      top: 50%;
      right: 1em;
      transform: translateY(-50%);
      font-family: $icon-font-family;
    }
    &--self,
    &--primary {
      &::after {
        content: $arrow-right;
      }
    }
    &--blank {
      &::after {
        content: $open-new-window;
      }
    }
    &--pdf {
      &::after {
        content: $pdf;
      }
    }
    &--plus {
      &::after {
        content: $plus;
      }
    }
    &--primary {
      border-color: $secondary;
    }
    &--anker {
      &::after {
        content: $chevron-down;
      }
    }
    @media (hover: hover) {
      &:hover {
        opacity: 1;
        background-color: $primary-container;
      }
    }
  }
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  &--left {
    justify-content: flex-start;
  }
  &--right {
    justify-content: flex-end;
  }
}
