@charset 'UTF-8';

$font-path-jp: '/assets/fonts/jp' !default;
$font-path-en: '/assets/fonts/en' !default;

$font-family-jp: 'Noto Sans JP';
$font-family-en: 'Roboto';
$font-family-raleway: 'Raleway';
$font-family-arizonia: 'Arizonia';

@font-face {
  font-family: '#{$font-family-jp}';
  src: url('#{$font-path-jp}/NotoSansJP-Regular.woff2') format('woff2'), url('#{$font-path-jp}/NotoSansJP-Regular.woff') format('woff'), url('#{$font-path-jp}/NotoSansJP-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
/* bold */
@font-face {
  font-family: '#{$font-family-jp}';
  src: url('#{$font-path-jp}/NotoSansJP-Bold.woff2') format('woff2'), url('#{$font-path-jp}/NotoSansJP-Bold.woff') format('woff'), url('#{$font-path-jp}/NotoSansJP-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
/* normal-italic*/
@font-face {
  font-family: '#{$font-family-jp}';
  src: url('#{$font-path-jp}/NotoSansJP-Regular.woff') format('woff2'), url('#{$font-path-jp}/NotoSansJP-Regular.woff') format('woff'), url('#{$font-path-jp}/NotoSansJP-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: '#{$font-family-en}';
  src: url('#{$font-path-en}/Roboto-Regular.woff2') format('woff2'), url('#{$font-path-en}/Roboto-Regular.woff') format('woff'), url('#{$font-path-en}/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
/* bold */
@font-face {
  font-family: '#{$font-family-en}';
  src: url('#{$font-path-en}/Roboto-Bold.woff2') format('woff2'), url('#{$font-path-en}/Roboto-Bold.woff') format('woff'), url('#{$font-path-en}/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
/* normal-italic */
@font-face {
  font-family: '#{$font-family-en}';
  src: url('#{$font-path-en}/Roboto-Italic.woff') format('woff2'), url('#{$font-path-en}/Roboto-Italic.woff') format('woff'), url('#{$font-path-en}/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: '#{$font-family-raleway}';
  src: url('#{$font-path-en}/Raleway-Regular.woff2') format('woff2'), url('#{$font-path-en}/Raleway-Regular.woff') format('woff'), url('#{$font-path-en}/Raleway-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
/* light */
@font-face {
  font-family: '#{$font-family-raleway}';
  src: url('#{$font-path-en}/Raleway-Light.woff2') format('woff2'), url('#{$font-path-en}/Raleway-Light.woff') format('woff'), url('#{$font-path-en}/Raleway-Light.ttf') format('truetype');
  font-weight: lighter;
  font-style: normal;
}
/* bold */
@font-face {
  font-family: '#{$font-family-raleway}';
  src: url('#{$font-path-en}/Raleway-Bold.woff2') format('woff2'), url('#{$font-path-en}/Raleway-Bold.woff') format('woff'), url('#{$font-path-en}/Raleway-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: '#{$font-family-arizonia}';
  src: url('#{$font-path-en}/Arizonia-Regular.woff2') format('woff2'), url('#{$font-path-en}/Arizonia-Regular.woff') format('woff'), url('#{$font-path-en}/Arizonia-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// Base font family
$base-font-family: '#{$font-family-jp}', sans-serif;
$base-font-family-en: '#{$font-family-en}', sans-serif;
$base-font-family-c: '#{$font-family-raleway}', sans-serif;
