@charset 'UTF-8';
@use './global/' as sys;
@use '../var/' as g;

.info-news-list {
  border-top: 1px solid g.$border-color;
  border-bottom: 1px solid g.$border-color;
  margin: 0;
  padding: 0;
  width: 100%;
  @include sys.media(lg) {
    border-top: none;
  }
}

.news-container {
  & + & {
    border-top: solid 1px g.$border-color;
  }
  &:last-child {
    margin-bottom: 0;
  }

  @media (hover: hover) {
    &:hover {
      background-color: g.$light-gray;
      color: g.$base;
    }
  }

  .news-body {
    width: 100%;
    padding: 1em 0;
  }

  .news-anchor {
    padding: 1.5rem 1rem 1.3rem 0.8rem;
    position: relative;
    transition: background-color g.$base-transition;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include sys.media(lg) {
      flex-wrap: nowrap;
      padding: 1.7rem 0.8rem;
    }
    @media (hover: hover) {
      &:hover {
        background-color: g.$primary-container;
      }
    }
  }

  .date {
    color: g.$base-40;
    font-family: g.$base-font-family;
    font-size: 1.1rem;
    white-space: nowrap;
    @include sys.media(lg) {
      font-size: 1.4rem;
      letter-spacing: 0.01em;
    }
  }

  .category,
  .news-category {
    margin-left: 1.4rem;
    @include sys.media(lg) {
      margin-left: 1.8rem;
    }

    .category-label,
    .category-item {
      color: g.$base;
      display: block;
      font-size: 1.1rem;
      font-weight: bold;
      white-space: nowrap;
      width: 100%;
      @include sys.media(lg) {
        min-width: 4em;
        font-size: 1.3rem;
      }
    }
  }
  .title {
    color: g.$base;
    display: block;
    width: 100%;
    position: relative;
    font-size: 1.3rem;
    margin-top: 0.2rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    // padding-right: 2rem;
    @include sys.media(lg) {
      margin-left: 2rem;
      letter-spacing: 0.03em;
    }
    // &::after {
    //   content: g.$arrow-right';
    //   font-family: g.$icon-font-family;
    //   position: absolute;
    //   right: 0;
    //   top: 50%;
    //   transform: translateY(-50%);
    // }
  }
}

.news-post-date {
  display: flex;
  align-items: center;
  position: relative;
  color: g.$gray-40;
  font-weight: bold;
  font-size: 0.875em;
  margin-bottom: 2em;
  &::before {
    content: g.$calendar;
    font-family: g.$icon-font-family;
    color: g.$gray-40;
    display: inline-block;
    margin-right: 0.5rem;
    transition: opacity g.$base-transition;
  }
}

.pagination-container {
  margin-top: 2.8rem;
}
