@charset 'UTF-8';

@use '../global/' as *;

// variable
$modal-active-class-name: '.active';

.modal-outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  visibility: hidden;
  transition: all $base-transition;
  z-index: 9999;
  &#{$modal-active-class-name} {
    opacity: 1;
    visibility: visible;
  }
}

.modal-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 90%;
  max-width: 720px;
  height: fit-content;
  margin: auto;
  padding: 1.6rem;
  border-radius: $base-border-radius;
  // background-color: $white;
  @include media(md) {
    padding: 3.2rem;
  }
}

.js-modal-close {
  position: fixed;
  top: 0.5rem;
  right: 1.4rem;
  color: $white;
  font-size: 2.4rem;
  cursor: pointer;
  @include media(lg) {
    top: 1.2rem;
    right: 2.6rem;
    font-size: 3rem;
  }
}
