@charset 'UTF-8';

@use '../global/' as *;

.table {
  width: 100%;
  border-collapse: collapse;
  thead {
    th {
      color: $white;
      background-color: $table-thead-th-background;
      border: 1px solid $white;
      text-align: center;
    }
  }
  th,
  td {
    padding: 1.4rem 1.2rem;
    font-weight: normal;
    text-align: left;
    border: 1px solid $table-border-color;
  }
  th {
    background-color: $table-th-background-color;
  }
  td {
    background-color: $table-td-background-color;
    overflow-wrap: break-word;
  }
}

// スクロールを出すための wrapper
.table-scroll-wrapper {
  width: 100vw;
  max-width: 100%;
  position: relative;
  overflow: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  @include media(md) {
    width: 100%;
    overflow: visible !important;
    white-space: normal;
  }
}

.table-responsive {
  width: 100%;
  border: none;
  & > thead {
    display: none;
  }
  & > tbody {
    & > tr {
      &:last-child {
        td {
          border-bottom: solid 1px $table-border-color;
        }
        @include media(md) {
          border-bottom: none;
        }
      }
      & > th,
      & > td {
        width: 100%;
        display: block;
        border: solid 1px $table-border-color;
        border-bottom: none;
        @include media(md) {
          width: auto;
          display: table-cell;
          border-bottom: 1px solid $table-border-color;
        }
      }
      & > th {
        border-bottom: none;
        @include media(md) {
          width: 30%;
          border-bottom: solid 1px $table-border-color;
        }
      }
    }
  }
}

//th_中央寄せ
.table_thcenter {
  th {
    text-align: center;
  }
}

//td_中央寄せ
.table_tdcenter {
  td {
    text-align: center;
  }
}

.table-layout-fixed {
  width: max-content;
  max-width: 100%;
  table-layout: fixed;
}
