@charset 'UTF-8';

@use '../global/' as *;

.footer-wrapper {
  padding: 0.6rem;
  position: relative;
  background-color: $white;
  @include media(lg) {
    padding: 1.2rem;
  }
}
.footer-container {
  border-radius: 2rem;
  background-color: $footer-container;
  @include media(lg) {
    border-radius: 3rem;
  }
}

.footer-inner {
  padding: 1.8rem 1.5rem 9rem;
  @include media(lg) {
    padding: 7rem 8.4rem;
  }
}

.footer-nav-wrapper,
.footer-info-wrapper {
  @include media(lg) {
    display: flex;
    justify-content: space-between;
  }
}

.footer-info-wrapper {
  @include media(lg) {
    align-items: flex-end;
  }
}

// navigation
.footer-nav {
  position: relative;
  margin-bottom: 3.3rem;
  @include media(lg) {
    padding-right: 5.6rem;
    flex: 1;
  }
}
.footer-nav-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  @include media(lg) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
  }
}
.footer-nav-link,
.footer-nav-category {
  display: block;
  border-bottom: 1px solid $border-color;
  padding: 1.6rem 0;
  transition: opacity $base-transition;
  position: relative;
  &::first-line {
    line-height: 1;
  }
  &::after {
    content: '';
    display: block;
    border-bottom: 1px solid $base-30;
    width: 2rem;
    position: absolute;
    left: 0;
    bottom: -1px;
  }
  span {
    line-height: 1.6;
  }
  .name-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    &::before {
      content: $chevron-right;
      font-family: $icon-font-family;
      font-size: 1.4rem;
      width: 2rem;
      height: 2rem;
      border: 1.2px solid $base-40;
      border-radius: 50%;
      margin-right: 0.5rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding-top: 1px;
    }
    span {
      flex: 1;
    }
  }

  &.js-trigger {
    .name-wrapper {
      &::after {
        content: $plus;
        font-family: $icon-font-family;
        font-size: 2rem;
        .active & {
          content: $minus;
        }
        @include media(lg) {
          display: none;
        }
      }
    }
  }
}
.footer-nav-link {
  .name-wrapper {
    &::before {
      content: $arrow-right;
      font-family: $icon-font-family;
    }
  }
}

.footer-nav-category {
  .name-wrapper {
    &::before {
      content: $c-etc;
      font-family: $icon-font-family;
    }
  }
}

.footer-nav-child {
  max-height: 0;
  overflow: hidden;
  transition: max-height $base-transition;
  background-color: $gray-container;
  @include media(lg) {
    max-height: none;
    margin-top: 2rem;
    background-color: transparent;
  }
}

.footer-nav-child-inner {
  padding: 0 1.8rem;
  @include media(lg) {
    padding: 0;
  }
}

.footer-nav-child-list {
  li {
    &:not(:last-child) {
      @include media(lg) {
        margin-bottom: 1rem;
      }
    }
  }
  .footer-nav-child-link {
    padding: 1.6rem 0;
    border-bottom: 1px solid $border-color;
    font-size: 1.3rem;
    display: block;
    color: $base-70;
    @include media(lg) {
      color: $base-50;
      padding: 0;
      border: none;
      display: inline-block;
    }
  }
  li:last-child {
    .footer-nav-child-link {
      border: none;
    }
  }
}

.footer-link {
  @include media(lg) {
    width: 20%;
    max-width: 28rem;
  }
  .bnr-list {
    margin: 5.3rem auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.8rem;
    @include media(lg) {
      margin: 4.8rem auto;
      display: block;
    }
  }
}

.footer-address {
  margin-bottom: 3.7rem;
  font-size: 1.2rem;
  color: $base-50;
  @include media(lg) {
  }
}

.footer-sns {
  .footer-sns-list {
    margin-bottom: 4rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    .icon-wrapper {
      width: 3.6rem;
      height: 3.6rem;
      border: 1px solid $base-50;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 1.6rem;
      }
    }
  }
}
