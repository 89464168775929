@charset 'UTF-8';
@use './global/' as sys;
@use '../var/' as g;

.closure-section-container {
  & + & {
    margin-top: 4rem;
    @include sys.media(lg) {
      margin-top: 7.2rem;
    }
  }
}

.closure-section-name {
  font-size: g.$h2-font-size;
  font-weight: normal;
  margin-bottom: g.$h2-margin-bottom;
  padding: 1.8rem 2rem;
  border-top: 1px solid g.$border-color;
  border-bottom: 1px solid g.$border-color;
  &:not(:first-child) {
    margin-top: g.$h2-margin-top;
  }
  @include sys.media(lg) {
    font-size: 2.4rem;
    margin-bottom: 3.2rem;
    padding: 2.4rem 0;
  }
}

.closure-item-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  @include sys.makeContainer;
  @include sys.media(lg) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    padding: 0;
  }
}

.closure-item-container {
  border: 1px solid g.$border-color;
  padding: 0.8rem;
  height: 100%;
  text-align: center;
  border-radius: g.$base-border-radius;
  @include sys.media(lg) {
    padding: 1.6rem;
  }

  &._closure {
    .closure-title {
      background-color: sys.$closure-title-bg-color;
    }
  }
  &._assist {
    .closure-title {
      background-color: sys.$assist-title-bg-color;
    }
  }
}

.closure-title {
  background-color: g.$gray-20;
  color: g.$white;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0.4rem;
  border-radius: 3px;
  @include sys.media(lg) {
    font-size: 1.5rem;
  }
}

.closure-date {
  margin: 1rem auto;
  font-size: 1.5rem;
  color: g.$body-text;
}

.closure-timezone {
  margin-bottom: 1rem;
  span {
    padding: 0.2rem 0.8rem;
    display: inline-block;
    font-size: 1.7rem;
    &.timezone_1 {
      background-color: #fff9ec;
    }
    &.timezone_2 {
      background-color: g.$primary-container;
    }
  }
}

.closure-doctor-container {
  display: flex;
  justify-content: center;
}

.closure-doctor {
  font-weight: bold;
  margin-top: 0.5em;
  margin-bottom: 1rem;
  & + .closure-doctor:before {
    content: '→';
    display: inline-block;
    padding: 0 0.5rem;
  }
}

.closure-comment {
  font-size: 1.5rem;
  color: g.$tertiary;
}
