@charset 'UTF-8';

@use '../global/' as *;

// Construct layout
.construct-container {
  display: flex;
  flex-flow: column nowrap;
  align-content: space-between;
  width: 100%;
  min-height: 100vh;
  &#{$burger-open-class} {
    overflow: hidden;
  }
  @include media(lg) {
    padding-top: 0;
  }
}

.construct-header {
  background-color: transparent;
  flex: none;
  width: 100%;
  position: relative;
  z-index: $z-index-header;
}

.construct-nav {
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform $base-transition;
  width: 100%;
  height: 100vh;
  z-index: $z-index-nav;
  // OPEN
  #{$burger-open-class} & {
    transform: translateX(0);
  }
  @include media(lg) {
    width: 90%;
    max-width: 1087px;
  }
}

.construct-overlay {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: opacity $base-transition;
  #{$burger-open-class} & {
    height: 100vh;
    opacity: 1;
    background-color: $base-50;
  }
}

.construct-main {
  background-color: transparent;
  flex: 1 0 auto;
  width: 100%;
  z-index: $z-index-main;
  overflow: hidden;
}

// Container
.container {
  padding-top: 1.6rem;
  padding-bottom: 12rem;
  @include media(lg) {
    padding-top: 2rem;
  }
  &.top {
    background-color: $white;
  }
  // @include makeContainer;
}

// section
.section {
  & + & {
    @each $key, $value in $breakpoints {
      @include media($key, true) {
        margin-top: map-get($section-block-gutters, $key);
      }
    }
  }
}
