@charset 'UTF-8';
@use './global/' as *;

a {
  display: inline-block;
  transition: all $base-transition;
  word-wrap: break-word;
  @media (hover: hover) {
    &:hover {
      opacity: 0.5;
    }
  }
  &.link {
    color: $link-color;
    &:not(.icon) {
      border-bottom: 1px solid $link-color;
    }
    &.icon {
      position: relative;
      &::before {
        content: '';
        display: block;
        border-bottom: 1px solid $link-color;
        background: transparent !important;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
      }
    }
  }
  &[href*='tel:'] {
    @include media(lg) {
      pointer-events: none;
      cursor: default;
      text-decoration: none;
    }
  }
}

hr {
  border-color: $border-color;
  &.liner {
    margin: 2.5rem auto;
    &--dashed {
      border-style: dashed;
    }
  }
}

//モバイル非表示
.pc {
  display: none !important;
  @include media(xl) {
    display: block !important;
  }
}

//PC非表示
.md {
  @include media(xl) {
    display: none !important;
  }
}

.corner {
  width: 5rem;
  height: 5rem;
  background-color: $white;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 0 0 2rem;
  z-index: 1;
  @include media(lg) {
    width: 7.8rem;
    height: 7.8rem;
    border-radius: 0 0 0 3rem;
  }
  &--top,
  &--bottom {
    display: block;
    width: 2rem;
    height: 2rem;
    position: absolute;
    overflow: hidden;
    &::before {
      content: '';
      display: block;
      width: 200%;
      height: 200%;
      position: absolute;
      top: 0px;
      left: 0px;
      background: transparent;
      border-radius: 50%;
      box-shadow: 2rem 2rem 0 0 $white;
      transform: translate(-50%, -50%);
    }
  }
  &--top {
    top: 0;
    left: 0;
    transform: translateX(-100%) rotate(-90deg);
  }
  &--bottom {
    right: 0;
    bottom: 0;
    transform: translateY(100%) rotate(-90deg);
  }

  @include media(lg) {
  }
}

.page {
  &.container {
    @include media(lg) {
      @include makeContainer;
    }
  }
}

.content-inner {
  @include makeContainer;
  @include media(lg) {
    padding: 0;
    width: 100%;
    max-width: 100%;
  }
}

// Photo Layout
.common-photo {
  .photo {
    &:first-child {
      margin-bottom: 2rem;
    }
    .photo-inner {
      margin-bottom: 1rem;
      @include media(md) {
        text-align: center;
      }
    }
    .photo-note {
      font-size: 1.3rem;
      @include media(md) {
        font-size: 1.4rem;
        text-align: left;
      }
    }
    img {
      width: 100%;
      border-radius: $base-border-radius;
      @include media(md) {
        width: auto;
      }
    }
  }
  .text {
    &:first-child {
      margin-bottom: 2.8rem;
    }
  }

  &.photo-center {
    .photo {
      @include media(md) {
        margin: 0 auto 23px;
        width: fit-content;
      }
    }
  }
  &.photo-right,
  &.photo-left {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include media(md) {
      flex-direction: row;
    }
    .photo,
    .text {
      @include media(md) {
        width: calc(50% - 20px);
      }
    }
    .photo {
      img {
        @include media(md) {
          width: 100%;
        }
      }
    }
    &.photo-reverse {
      @include media(md) {
        flex-direction: row-reverse;
      }
    }
  }
  &.photo-acenter {
    align-items: center;
  }
}
.photo-float {
  overflow: hidden;
  &-item {
    float: left;
    margin: 0 2rem 2rem 0;
    @include media(md) {
      width: 30%;
    }
  }
}

.img-responsive {
  width: 100%;
  max-width: max-content;
  height: auto;
  display: block;
  margin: 0 auto;
  @include media(md) {
    max-width: 100%;
  }
}

// Box
.border-box {
  border: 1px solid $border-color;
  @each $name, $color in $all-colors {
    &--#{$name} {
      border-color: $color;
    }
  }
}

.color-box {
  background-color: $gray-90;
  border-radius: $base-border-radius;
  @each $name, $color in $all-colors {
    &--#{$name} {
      background-color: $color;
    }
  }
}

.border-box,
.color-box {
  padding: 2rem;
  border-radius: $base-border-radius;
  &--contact {
    text-align: center;
  }
  @include media(lg) {
    padding: 2.8rem;
  }
}

.text-wrapper {
  margin: 2.4rem auto;
  padding: 2rem;
  @include media(lg) {
    padding: 2.8rem;
  }
  p:last-child {
    margin-bottom: 0;
  }
  &--contact {
    padding: 8%;
  }
  .grid > div:not(:last-child) {
    margin-bottom: 1.2rem;
  }
  @include media(lg) {
    padding: 3.8rem 4rem 4.2rem;
    &--contact {
      padding: 4rem;
    }
    .grid > div:not(:last-child) {
      margin-bottom: 0;
    }
  }
}

// Maker
.marker {
  background-image: linear-gradient(90deg, $primary-marker 90%, transparent);
  background-position: center bottom;
  background-repeat: repeat-x;
  background-size: auto 1.3em;
  padding: 0 0.25em;
}

.marker-animation {
  padding: 0 0.25em;
  &.is-visible {
    background-image: linear-gradient(90deg, $primary-marker 90%, transparent);
    background-repeat: no-repeat;
    background-size: 200% 1.3em;
    /*2つ目の値でマーカーの太さを変える*/
    background-position: 0% bottom;
    animation: marker 3s ease 0s;
    /*マーカーを引く速度*/
  }
}

@keyframes marker {
  0% {
    background-position: right -100% bottom;
  }
  100% {
    background-position: 0% bottom;
  }
}

// 注釈
.indent {
  padding-left: 1em;
  text-indent: -1em;
}

// カード
.card {
  height: 100%;
  font-size: 1.4rem;
  @include media(lg) {
    font-size: 1.6rem;
  }
  &__link,
  &__item {
    height: 100%;
    border-radius: $base-border-radius;
    border: 1px solid $base;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .card__content {
      flex: 1;
    }
  }
  &__link {
    @media (hover: hover) {
      &:hover {
        opacity: 1;
        background-color: $primary-container;
      }
    }
  }
  &__photo {
    border-bottom: 1px solid $base;
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  &__more {
    padding: 1.6rem 2rem;
    display: block;
    position: relative;
    @include media(lg) {
      padding: 2rem 2.8rem;
    }
    &::after {
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      top: 50%;
      right: 2rem;
      transform: translateY(-50%);
      content: $arrow-right;
      font-family: $icon-font-family;
      font-size: 1.4rem;
      background: $primary;
    }
    &--inbox {
      padding-top: 0;
      padding-left: 0;
      padding-bottom: 0;
      &::after {
        right: 0;
      }
    }
  }
  &__title {
    margin-bottom: 0.8rem;
    color: $base;
    @include media(lg) {
      margin-bottom: 1.6rem;
    }
  }
  &__content {
    padding: 2rem;
    @include media(lg) {
      padding: 2.8rem;
    }
  }
  &__content ~ &__more {
    border-top: 1px solid $base;
  }
  &--image {
    .card__link {
      padding: 0;
    }

    .card__more {
      padding-left: 1.8rem;
      padding-right: 1.8rem;
      @include media(lg) {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
      }
      &::after {
        right: 1.8rem;
      }
    }
  }
  .heading-point {
    margin: 0 auto 1.4rem;
    font-size: 1.8rem;
  }
  .text-point {
    margin-bottom: 1.6rem;
    font-family: $base-font-family-c;
    font-size: 1.6rem;
    font-weight: lighter;
    span {
      font-size: 2.2rem;
      padding-left: 0.8rem;
    }
  }
}
.numbered-card {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  hr {
    margin: 1.6rem auto;
  }
  .numbered-card-box {
    width: 100%;
    border: 1px solid $base;
    border-radius: 1.6rem;
    background-color: $primary-container;
    padding: 2rem;
    @include media(lg) {
      width: calc(50% - 1rem);
      padding: 3.2rem;
    }
  }
  .numbered-card-box-title {
    margin-bottom: 0;
    &--icon {
      display: flex;
      gap: 2rem;
      align-items: center;
    }
    &__num {
      margin-bottom: 0.8rem;
      display: block;
      font-size: 1.6rem;
      font-family: $base-font-family-c;
      color: $tertiary;
      @include media(lg) {
        font-size: 1.8rem;
      }
    }
    &__text {
      display: block;
      font-size: 1.6rem;
      font-weight: bold;
      @include media(lg) {
        font-size: 2.2rem;
      }
    }
  }
}

// アンカーリンク
.contents-list {
  margin-bottom: 4rem;
  padding: 0.5rem 4rem;
  border-left: 1px solid $border-color;
  a {
    color: $link-color;
    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  @include media(lg) {
    margin: 40px 0 40px;
    &:first-child {
      margin-top: 0;
    }
  }
  @include media(sm) {
    .underline {
      text-decoration: underline !important;
    }
  }
  .title {
    margin-bottom: 15px;
  }
  li {
    padding-left: 1.4em;
    position: relative;
    &::before {
      width: 8px;
      height: 8px;
      background: $link-color;
      border-radius: 8px;
      position: absolute;
      left: 0;
      top: 11px;
      content: '';
    }
    & + li {
      margin-top: 1.6rem;
    }
  }
}

// ショウケース
.showcase {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  .showcase-box {
    width: 100%;
    padding: 1.6rem;
    border: 1px solid $base;
    border-radius: 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    @include media(sm) {
      width: calc(100% / 2 - 1rem);
    }
    @include media(md) {
      width: calc(100% / 3 - 1.4rem);
    }
    &:nth-child(odd) {
      background-color: $primary-container;
    }
    &:nth-child(even) {
      background-color: $secondary-container;
    }
    &-title {
      margin-bottom: 0.8rem;
      padding-bottom: 0.8rem;
      border-bottom: 1px solid $border-color;
      font-size: 1.8rem;
      font-weight: bold;
    }
  }
}

// ページタイトル
.page-hero {
  margin: 0.4rem;
  position: relative;
  @include media(md) {
    margin: 1.2rem;
  }
  &-inner {
    position: absolute;
    top: 5rem;
    left: 0;
    width: 100%;
    height: calc(100% - 5rem);
    padding: 2.6rem;
    display: flex;
    align-items: center;
    z-index: 1;
    @include media(lg) {
      padding: 4rem 5% 3rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &-picture {
    position: relative;
    border-radius: 2rem;
    display: block;
    overflow: hidden;
    @include media(md) {
      border-radius: 3rem;
    }
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 0.01%, rgba(0, 0, 0, 0.2) 30.33%);
      z-index: 1;
    }
    img {
      width: 100%;
      height: 100vw;
      object-fit: cover;
      @include media(md) {
        height: 40vw;
      }
    }
    &--empty {
      &::before {
        display: none;
      }
      img {
        height: 74vw;
        max-height: 276px;
        @include media(md) {
          height: 26vw;
          max-height: 348px;
        }
      }
    }
  }
  &-title {
    color: $white;
    &__heading {
      display: flex;
      overflow: hidden;
      span {
        transform: translateY(2em);
      }
      &--en {
        background: $gradation;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: $base-font-family-c;
        font-size: 3.5rem;
        line-height: $heading-line-height;
        opacity: 0;
        @include media(md) {
          font-size: 4.5rem;
        }
        @include media(lg) {
          font-size: 6rem;
          font-weight: lighter;
          line-height: 1.1;
        }
        @include media(xl) {
          font-size: 8rem;
        }
      }
    }
    &__description {
      font-size: 1.4rem;
      font-weight: bold;
      opacity: 0;
      @include media(md) {
        font-size: 2rem;
      }
    }
    &--empty {
      .page-hero-title__heading--en {
        position: absolute;
        bottom: -0.28em;
        left: 2rem;
        color: rgba(255, 255, 255, 0.06);
        background: none;
        white-space: nowrap;
        -webkit-text-fill-color: unset;
        @include media(md) {
          left: 3rem;
        }
        @include media(lg) {
          font-size: 7rem;
          bottom: -0.19em;
        }
        @include media(xl) {
          font-size: 12rem;
        }
        br {
          display: none;
        }
      }
    }
  }
  &.is-visible {
    .page-hero-title__heading {
      span {
        $delay: 0.1;
        @for $i from 1 through 25 {
          &:nth-child(#{$i}) {
            animation: textIn 0.8s cubic-bezier(0.33, 1, 0.68, 1) ($delay * $i + s) forwards;
          }
        }
      }
    }
    .page-hero-title__heading--en {
      animation: fadeUp 1.3s cubic-bezier(0.33, 1, 0.68, 1) 1s forwards;
    }
    .page-hero-title__description {
      animation: fadeIn 1.3s cubic-bezier(0.33, 1, 0.68, 1) 2.3s forwards;
    }
    .page-hero-picture {
      &:not(.page-hero-picture--empty) {
        img {
          animation: zoomOut 3.8s ease-in-out forwards;
        }
      }
    }
  }
}

// contact-box
.contact-box {
  font-size: 1.5rem;
  .contact-btn-list {
    margin: 2.4rem auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.6rem;
    .btn {
      padding-left: 3.8rem;
      padding-right: 3.8rem;
    }
  }
  hr {
    margin: 3.5rem auto;
    border-color: $primary;
  }
  .heading,
  .lead {
    word-break: keep-all;
  }
}

// Link list
.navigation-list {
  font-size: 1.3rem;
  &__item:not(:last-child) {
    margin-bottom: 1.3rem;
  }
  a {
    color: $base-50;
  }
}

// 卒業生インタビュー
.interview {
  .interview-wrapper {
    padding-bottom: 1.25rem;
    overflow: auto;
  }
  .interview-list {
    width: 100%;
    min-width: max-content;
    display: flex;
    overflow: visible;
    margin-left: 2rem;
    margin-right: 2rem;
    gap: 0.9rem;
    @include media(md) {
      min-width: auto;
      margin: 0;
      padding: 0 2rem;
      gap: 1.6rem;
    }
    @include media(lg) {
      margin-left: 0;
      padding: 0;
    }
  }
  .interview-item {
    width: 248px;
    position: relative;
    @include media(md) {
      width: calc(100% / 3);
    }
    &__picture {
      margin-bottom: 0.8rem;
      aspect-ratio: 3 / 4;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        border-radius: 2rem;
        object-fit: cover;
        object-position: center top;
        @include media(lg) {
          &--campuslife {
            height: 418px;
          }
        }
      }
      .corner {
        width: 4.4rem;
        height: 4.4rem;
        top: auto;
        bottom: 0;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        background-color: $white;
        @include media(lg) {
          width: 5.4rem;
          height: 5.4rem;
        }
        &--top,
        &--bottom {
          width: 1rem;
          height: 1rem;
          &::before {
            box-shadow: 1rem 1rem 0 0 $white;
          }
        }
      }
    }
    &__icon {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 3.4rem;
      height: 3.4rem;
      text-align: center;
      background-color: $primary;
      border-radius: 50%;
      z-index: 1;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
      @include media(lg) {
        width: 4.4rem;
        height: 4.4rem;
        font-size: 2rem;
      }
    }
    &__employer {
      position: absolute;
      top: 1.5rem;
      left: 1.2rem;
      background-color: $white;
      padding: 0.9rem;
      font-size: 1.2rem;
      line-height: 1;
      border-radius: 5rem;
      @include media(lg) {
        padding: 1rem 1.2rem;
        font-size: 1.5rem;
        left: 1.5rem;
      }
    }
  }
  .interview-link {
    position: relative;
    height: 100%;
    display: block;
  }
}

// Swiper
.swiper-tools {
  margin-top: 2.8rem;
  @include media(lg) {
    display: flex;
    align-items: center;
    margin-top: 4.2rem;
    padding-bottom: 8px;
  }
  .swiper-pagination,
  .swiper-pagination-horizontal {
    @include media(lg) {
      position: static;
    }
  }

  .swiper-pagination {
    position: static;
    background-color: #f4f4f4;
    border-radius: 4px;
    overflow: hidden;
    @include media(lg) {
      margin-top: -1.6rem;
      padding-left: 0;
      width: calc(100% - 17.6rem);
      flex: 1;
    }
    .swiper-pagination-progressbar-fill {
      background-color: #dfdfdf;
    }
  }
}

// masonry
.js-grid-item {
  width: 100%;
  @include media(lg) {
    width: calc((100% - 6rem) / 3);
    margin-bottom: 3rem;
  }
}

// Animation
.construct-header {
  .header-inner-left,
  .header-inner-center,
  .header-menu-list {
    opacity: 0;
  }
  &.is-visible {
    .header-inner-left,
    .header-inner-center,
    .header-menu-list {
      animation: fadeIn 1.5s cubic-bezier(0.33, 1, 0.68, 1) 1s forwards;
    }
  }
}

// Youtube
.youtube {
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 2rem;
  overflow: hidden;
  @include media(lg) {
    border-radius: 3rem;
  }
}
.youtube iframe {
  width: 100%;
  height: 100%;
}
