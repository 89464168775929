@charset 'UTF-8';

@use '../global/' as *;

// breadlist-wrapper
//
.breadlist-wrapper {
  margin-bottom: 3rem;
  padding: 1rem 0;
  overflow: auto;
  @include media(lg) {
    margin-bottom: 3.6rem;
  }
}

// breadlist-container
//
.breadlist-container {
  padding: 0 2rem;
  @include media(lg) {
    padding: 0 7.2rem;
  }
  // @include makeContainer();
}

// breadlist
//
ol.breadlist {
  display: flex;
  align-items: baseline;
  font-size: 1.2rem;
  @include media(lg) {
    justify-content: flex-end;
  }

  li {
    color: inherit;
    line-height: 1;
    text-overflow: ellipsis;
    white-space: nowrap;

    & + li::before {
      content: '／';
      color: $base-50;
      padding-right: 0.5em;
      padding-left: 0.5em;
    }

    a {
      color: $base;
      text-decoration: underline;
      &::active,
      &::visited {
        color: inherit;
      }
    }

    &.current {
      color: $base-50;
      font-weight: normal;
    }
  }
}
