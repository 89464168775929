@charset 'UTF-8';

@use '../global/' as *;

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-weight: bold;
  line-height: $heading-line-height;
  letter-spacing: $heading-letter-spacing;
  text-rendering: optimizeLegibility;
}

h1,
.heading01 {
  font-size: $h1-font-size;
  margin-bottom: $h1-margin-bottom;
  &:not(:first-child) {
    margin-top: $h1-margin-top;
  }
  @include media(lg) {
    font-size: 2.6rem;
    margin-bottom: 2rem;
    &:not(:first-child) {
      margin-top: 2rem;
    }
  }
}

h2,
.heading02 {
  font-size: $h2-font-size;
  margin-bottom: $h2-margin-bottom;
  &:not(:first-child) {
    margin-top: $h2-margin-top;
  }
  @include media(lg) {
    font-size: 2.8rem;
    margin-bottom: 4rem;
  }
}

h3,
.heading03 {
  font-size: $h3-font-size;
  margin-bottom: $h3-margin-bottom;
  &:not(:first-child) {
    margin-top: $h3-margin-top;
  }
  @include media(lg) {
    font-size: 2.4rem;
    margin-bottom: 3.2rem;
  }
}

h4,
.heading04 {
  font-size: $h4-font-size;
  margin-bottom: $h4-margin-bottom;
  &:not(:first-child) {
    margin-top: $h4-margin-top;
  }
  @include media(lg) {
    font-size: 1.8rem;
  }
}

h5,
.heading05 {
  font-size: $h5-font-size;
  margin-bottom: $h5-margin-bottom;
  &:not(:first-child) {
    margin-top: $h5-margin-top;
  }
  @include media(lg) {
    font-size: 1.6rem;
  }
}

h6,
.heading06 {
  font-size: $h6-font-size;
  margin-bottom: $h6-margin-bottom;
  &:not(:first-child) {
    margin-top: $h6-margin-top;
  }
  @include media(lg) {
    font-size: 1.5rem;
  }
}

// heading
h2.heading,
.heading02 {
  padding: 0 2rem 1.8rem;
  border-bottom: 4px solid;
  border-image: $gradation-heading 1;
  @include media(lg) {
    padding: 0 0 2.4rem;
  }
}
h3.heading,
.heading03 {
  padding-left: 2.6rem;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1.8rem;
    height: 1.8rem;
    border: 4px solid $primary;
    border-radius: 50%;
  }
  @include media(lg) {
    padding-left: 3rem;
  }
}

// Paragraph
.lead {
  font-size: inherit;
  letter-spacing: $lead-letter-spacing;
  line-height: $lead-line-height;
  text-rendering: optimizeLegibility;
  // .lead spacer
  & + & {
    margin-top: $lead-top-spacer;
  }
}

// フォントファミリーを日本語に限定する（※）
.mbf {
  font-family: -apple-system, BlinkMacSystemFont, 'Hiragino Sans', 'Original Yu Gothic', 'Yu Gothic', sans-serif !important;
}

// code
pre {
  background-color: $gray-90;
  padding: 1em;
  white-space: pre-wrap;
  word-wrap: break-word;
  code {
    background-color: transparent;
    color: $gray-30;
    font-family: 'SourceHanCodeJP-Regular', monospace;
    font-size: 0.8em;
    font-weight: bold;
    vertical-align: initial;
  }
}
