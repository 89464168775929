@charset 'UTF-8';

@use '../global/' as *;

@font-face {
  font-family: '#{$icon-font-family}';
  src: url('#{$icon-font-path}/#{$icon-font-family}.eot?omg6p');
  src: url('#{$icon-font-path}/#{$icon-font-family}.eot?omg6p#iefix') format('embedded-opentype'), url('#{$icon-font-path}/#{$icon-font-family}.ttf?omg6p') format('truetype'), url('#{$icon-font-path}/#{$icon-font-family}.woff?omg6p') format('woff'),
    url('#{$icon-font-path}/#{$icon-font-family}.svg?omg6p##{$icon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::before,
  &::after {
    line-height: 1;
    font-family: '#{$icon-font-family}' !important;
  }

  // default
  &::before {
    display: none;
    margin: 0 0.4em 0 0;
  }
  &::after {
    margin: 0 0 0 0.4em;
  }

  // left
  &.left {
    &::before {
      display: inline;
    }
    &::after {
      display: none;
    }
  }

  // center
  &.center {
    &::before,
    &::after {
      margin: 0 auto;
    }
  }

  &.link {
    display: inline-flex;
    align-items: center;
  }

  &.c-phone {
    &:before,
    &::after {
      content: $c-phone;
    }
  }

  &.flow-arrow {
    &::before,
    &::after {
      content: $flow-arrow;
    }
  }
  &.c-search {
    &::before,
    &::after {
      content: $c-search;
    }
  }
  &.c-movie {
    &::before,
    &::after {
      content: $c-movie;
    }
  }
  &.c-etc {
    &::before,
    &::after {
      content: $c-etc;
    }
  }
  &.c-close {
    &::before,
    &::after {
      content: $c-close;
    }
  }
  &.c-chevron-right {
    &::before,
    &::after {
      content: $c-chevron-right;
    }
  }
  &.c-chevron-left {
    &::before,
    &::after {
      content: $c-chevron-left;
    }
  }
  &.c-book {
    &::before,
    &::after {
      content: $c-book;
    }
  }
  &.headset {
    &::before,
    &::after {
      content: $headset;
    }
  }
  &.tree {
    &::before,
    &::after {
      content: $tree;
    }
  }
  &.search {
    &::before,
    &::after {
      content: $search;
    }
  }
  &.quiz {
    &::before,
    &::after {
      content: $quiz;
    }
  }
  &.map {
    &::before,
    &::after {
      content: $map;
    }
  }
  &.map-pin {
    &::before,
    &::after {
      content: $map-pin;
    }
  }
  &.home {
    &::before,
    &::after {
      content: $home;
    }
  }
  &.calendar {
    &::before,
    &::after {
      content: $calendar;
    }
  }
  &.datetime {
    &::before,
    &::after {
      content: $datetime;
    }
  }
  &.circle-phone {
    &::before,
    &::after {
      content: $circle-phone;
    }
  }
  &.document {
    &::before,
    &::after {
      content: $document;
    }
  }
  &.pdf {
    &::before,
    &::after {
      content: $pdf;
    }
  }
  &.warning-outline {
    &::before,
    &::after {
      content: $warning-outline;
    }
  }
  &.caution {
    &::before,
    &::after {
      content: $caution;
    }
  }
  &.caution-outline {
    &::before,
    &::after {
      content: $caution-outline;
    }
  }
  &.warning {
    &::before,
    &::after {
      content: $warning;
    }
  }
  &.phone {
    &::before,
    &::after {
      content: $phone;
    }
  }
  &.mail {
    &::before,
    &::after {
      content: $mail;
    }
  }
  &.plus {
    &::before,
    &::after {
      content: $plus;
    }
  }
  &.add {
    &::before,
    &::after {
      content: $add;
    }
  }
  &.add-outline {
    &::before,
    &::after {
      content: $add-outline;
    }
  }
  &.clear {
    &::before,
    &::after {
      content: $clear;
    }
  }
  &.minus {
    &::before,
    &::after {
      content: $minus;
    }
  }
  &.remove {
    &::before,
    &::after {
      content: $remove;
    }
  }
  &.remove-outline {
    &::before,
    &::after {
      content: $remove-outline;
    }
  }
  &.chevron-down {
    &::before,
    &::after {
      content: $chevron-down;
    }
  }
  &.chevron-left {
    &::before,
    &::after {
      content: $chevron-left;
    }
  }
  &.chevron-right {
    &::before,
    &::after {
      content: $chevron-right;
    }
  }
  &.chevron-up {
    &::before,
    &::after {
      content: $chevron-up;
    }
  }
  &.smart-phone {
    &::before,
    &::after {
      content: $smart-phone;
    }
  }
  &.radio {
    &::before,
    &::after {
      content: $radio;
    }
  }
  &.arrow-left {
    &::before,
    &::after {
      content: $arrow-left;
    }
  }
  &.caret-down {
    &::before,
    &::after {
      content: $caret-down;
    }
  }
  &.caret-up {
    &::before,
    &::after {
      content: $caret-up;
    }
  }
  &.arrow-right {
    &::before,
    &::after {
      content: $arrow-right;
    }
  }
  &.arrow-up {
    &::before,
    &::after {
      content: $arrow-up;
    }
  }
  &.arrow-down {
    &::before,
    &::after {
      content: $arrow-down;
    }
  }
  &.caret-left {
    &::before,
    &::after {
      content: $caret-left;
    }
  }
  &.caret-right {
    &::before,
    &::after {
      content: $caret-right;
    }
  }
  &.arrow-back {
    &::before,
    &::after {
      content: $arrow-back;
    }
  }
  &.arrow-forward {
    &::before,
    &::after {
      content: $arrow-forward;
    }
  }
  &.checkbox-checked {
    &::before,
    &::after {
      content: $checkbox-checked;
    }
  }
  &.checkbox {
    &::before,
    &::after {
      content: $checkbox;
    }
  }
  &.radio-checked {
    &::before,
    &::after {
      content: $radio-checked;
    }
  }
  &.open-new-window {
    &::before,
    &::after {
      content: $open-new-window;
    }
  }
  &.help {
    &::before,
    &::after {
      content: $help;
    }
  }
  &.delete {
    &::before,
    &::after {
      content: $delete;
    }
  }
}
