@charset 'UTF-8';
@use './global/' as *;

.loading-wrapper {
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 2rem;
  transition: opacity 0.5s ease-out, visibility 0.8s ease-out;
  z-index: $z-index-loading;
  @include media(lg) {
    padding: 3.4rem;
  }
  &.disabled {
    opacity: 0;
    visibility: hidden;
  }
}

.loading-container {
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  background: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3.6rem;
  padding: 2rem;
  @include media(lg) {
    border-radius: 3rem;
  }
}

.loading-content {
  height: 60%;
  max-height: 32rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.loading-text-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  // .loading-icon {
  //   animation: fuwafuwa 3s ease-in-out infinite alternate;
  //   background: url(/images/common/ico_loading.svg) no-repeat center center / 43px auto;
  //   display: inline-block;
  //   transition: 1.5s ease-in-out;
  //   width: 70px;
  //   height: 70px;
  //   margin-top: 15px;
  // }
  .loading-text {
    animation: move-text 1s infinite alternate ease-in-out;
  }
}

// @keyframes fuwafuwa {
//   0% {
//     transform: translate(0, 0) rotate(-7deg);
//   }
//   50% {
//     transform: translate(0, -7px) rotate(0deg);
//   }
//   100% {
//     transform: translate(0, 0) rotate(7deg);
//   }
// }

@keyframes move-text {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(5px);
  }
}
