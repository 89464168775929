@charset 'UTF-8';

@use '../global/' as *;
@use 'sass:math';

// wrapper
.global-nav-wrapper {
  height: 100%;
  padding: 4.8rem 3rem 10rem;
  overflow: auto;
  background-color: $white;
  @include media(lg) {
    padding: 8.8rem 10.4rem;
  }
  .navigation-list {
    @include media(lg) {
      padding-top: 3.5rem;
      border-top: 1px solid $border-color;
      display: flex;
      grid-gap: 1.5rem;
      gap: 1.5rem;
    }
  }
}

// navigation
.global-nav {
  position: relative;
  margin-bottom: 3.3rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  @include media(lg) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
  }
  & > .global-nav-item {
    @media (hover: hover) {
      opacity: 1;
      &.hover {
        .global-nav-link {
          opacity: 0.6;
        }
      }
    }
  }
}

.global-nav-item {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include media(lg) {
    display: block;
  }
  &.global-sub-item {
    display: block;
  }
  .js-trigger {
    border-bottom: 1px solid $border-color;
    &::after {
      content: $plus;
      font-family: $icon-font-family;
      font-size: 2rem;
      height: 100%;
      display: flex;
      align-items: center;
      @include media(lg) {
        display: none;
      }
    }
  }
  &.active {
    .js-trigger {
      &::after {
        content: $minus;
        height: 100%;
      }
    }
  }
}

.global-nav-link,
.global-nav-category {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $border-color;
  padding: 1.6rem 0;
  transition: opacity $base-transition;
  position: relative;
  flex: 1;
  &::first-line {
    line-height: 1;
  }
  &::after {
    content: '';
    display: block;
    border-bottom: 1px solid $base-30;
    width: 2rem;
    position: absolute;
    left: 0;
    bottom: -1px;
  }
  span {
    line-height: 1.6;
  }
  .name-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    &::before {
      content: '';
      width: 2.4rem;
      height: 2.4rem;
      border: 1.2px solid $base-40;
      border-radius: 50%;
      margin-right: 0.5rem;
    }
    span {
      flex: 1;
    }
  }
}

.global-nav-link {
  .name-wrapper {
    &::before {
      content: $arrow-right;
      font-family: $icon-font-family;
      font-size: 1.4rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.global-nav-category {
  .name-wrapper {
    &::before {
      content: $c-etc;
      font-family: $icon-font-family;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding-top: 1px;
    }
  }
}

// 子メニュー
//
.global-nav-child {
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height $base-transition;
  background-color: $gray-container;
  @include media(lg) {
    max-height: none;
    margin-top: 2rem;
    background-color: transparent;
  }
}

.global-nav-child-inner {
  padding: 0 1.8rem;
  @include media(lg) {
    padding: 0;
  }
}

.global-nav-child-list {
  li {
    &:not(:last-child) {
      @include media(lg) {
        margin-bottom: 1rem;
      }
    }
  }
  .global-nav-child-link {
    padding: 1.6rem 0;
    border-bottom: 1px solid $border-color;
    font-size: 1.3rem;
    color: $base-70;
    display: block;
    @include media(lg) {
      padding: 0;
      border: none;
      display: inline-block;
    }
  }
  li:last-child {
    .global-nav-child-link {
      border: none;
    }
  }
}
