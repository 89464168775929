@charset 'UTF-8';

@use '../global/' as *;

// variable
//
$pagetop-active-class-name: '.pagetop-active';

.pagetop {
  width: 34px;
  height: 34px;
  border: 1px solid $base;
  border-radius: 50%;
  background-color: $white;
  text-align: center;
  position: fixed;
  right: 1.2rem;
  bottom: 1.2rem;
  opacity: 0;
  visibility: hidden;
  transition: all $base-transition;
  cursor: pointer;
  z-index: $z-index-pagetop;
  &::before {
    content: $chevron-up;
    font-family: $icon-font-family;
    font-size: 1.2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @include media(md) {
    width: 44px;
    height: 44px;
    right: 2rem;
    bottom: 2rem;
    &::before {
      font-size: 1.4rem;
    }
  }

  &#{$pagetop-active-class-name} {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}
