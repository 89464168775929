@charset 'UTF-8';

/*-------------------------
  theme colors
-------------------------*/
$base: #333 !default;
$base-10: rgba($base, 0.1) !default;
$base-20: rgba($base, 0.2) !default;
$base-30: rgba($base, 0.3) !default;
$base-40: rgba($base, 0.4) !default;
$base-50: rgba($base, 0.5) !default;
$base-60: rgba($base, 0.6) !default;
$base-70: rgba($base, 0.7) !default;
$base-80: rgba($base, 0.8) !default;
$base-90: rgba($base, 0.9) !default;
$body-text: $base;

$primary: #fff55e !default;
$primary-marker: #fff991 !default;
$primary-container: #fffef2 !default;

$secondary: #31a9e9 !default;
$secondary-text: #1379b0 !default;
$secondary-container: #f5fafc !default;

$tertiary: #ffa9a9 !default;
$tertiary-container: #fff7f7 !default;

$error: #dc362e !default;
$footer-container: #f5f5f5 !default;

$border-color: $base-10 !default;
$text-color: $base !default;
$link-color: $base-70 !default;

/* primary
-------------------------*/
$primary-10: #292601 !default;
$primary-20: #575209 !default;
$primary-30: #857e18 !default;
$primary-40: #aba32b !default;
$primary-50: #ccc339 !default;
$primary-60: #e0d63d !default;
$primary-70: #f0e543 !default;
$primary-80: $primary !default;
$primary-90: $primary-marker !default;
$primary-95: #fffdd6 !default;
$primary-99: $primary-container !default;
$primary-on-high: rgba(
  $color: $primary,
  $alpha: 1,
) !default;
$primary-on-medium: rgba(
  $color: $primary,
  $alpha: 0.74,
) !default;
$primary-on-disabled: rgba(
  $color: $primary,
  $alpha: 0.38,
) !default;

/* secondary
-------------------------*/
$secondary-10: #012c42 !default;
$secondary-20: #064d73 !default;
$secondary-30: #0c6696 !default;
$secondary-40: $secondary-text !default;
$secondary-50: #1d93d1 !default;
$secondary-60: $secondary !default;
$secondary-70: #49b9f5 !default;
$secondary-80: #6dcafc !default;
$secondary-90: #9cdcff !default;
$secondary-95: #d6f1ff !default;
$secondary-99: $secondary-container !default;
$secondary-on-high: rgba(
  $color: $secondary,
  $alpha: 1,
) !default;
$secondary-on-medium: rgba(
  $color: $secondary,
  $alpha: 0.74,
) !default;
$secondary-on-disabled: rgba(
  $color: $secondary,
  $alpha: 0.38,
) !default;

/* error
-------------------------*/
$tertiary-10: #4d0c0c !default;
$tertiary-20: #7d1e1e !default;
$tertiary-30: #ba3c3c !default;
$tertiary-40: #de5d5d !default;
$tertiary-50: #f57a7a !default;
$tertiary-60: #ff8f8f !default;
$tertiary-70: $tertiary !default;
$tertiary-80: #ffc4c4 !default;
$tertiary-90: #ffd6d6 !default;
$tertiary-95: #ffebeb !default;
$tertiary-99: $tertiary-container !default;
$tertiary-on-high: rgba(
  $color: $tertiary,
  $alpha: 1,
) !default;
$tertiary-on-medium: rgba(
  $color: $tertiary,
  $alpha: 0.74,
) !default;
$tertiary-on-disabled: rgba(
  $color: $tertiary,
  $alpha: 0.38,
) !default;

$theme-colors: (
  'base': $base,
  'primary': $primary,
  'primary-marker': $primary-marker,
  'primary-container': $primary-container,
  'secondary': $secondary,
  'secondary-text': $secondary-text,
  'secondary-container': $secondary-container,
  'tertiary': $tertiary,
  'tertiary-container': $tertiary-container,
  'error': $error,
  'footer-container': $footer-container,
  'border-color': $border-color,
  'text-color': $text-color,
  'link-color': $link-color,
  'base-10': $base-10,
  'base-20': $base-20,
  'base-30': $base-30,
  'base-40': $base-40,
  'base-50': $base-50,
  'base-60': $base-60,
  'base-70': $base-70,
  'base-80': $base-80,
  'base-90': $base-90,
  'primary-10': $primary-10,
  'primary-20': $primary-20,
  'primary-30': $primary-30,
  'primary-40': $primary-40,
  'primary-50': $primary-50,
  'primary-60': $primary-60,
  'primary-70': $primary-70,
  'primary-80': $primary-80,
  'primary-90': $primary-90,
  'primary-95': $primary-95,
  'primary-99': $primary-99,
  'primary-on-high': $primary-on-high,
  'primary-on-medium': $primary-on-medium,
  'primary-on-disabled': $primary-on-disabled,
  'secondary-10': $secondary-10,
  'secondary-20': $secondary-20,
  'secondary-30': $secondary-30,
  'secondary-40': $secondary-40,
  'secondary-50': $secondary-50,
  'secondary-60': $secondary-60,
  'secondary-70': $secondary-70,
  'secondary-80': $secondary-80,
  'secondary-90': $secondary-90,
  'secondary-95': $secondary-95,
  'secondary-99': $secondary-99,
  'secondary-on-high': $secondary-on-high,
  'secondary-on-medium': $secondary-on-medium,
  'secondary-on-disabled': $secondary-on-disabled,
  'tertiary-10': $tertiary-10,
  'tertiary-20': $tertiary-20,
  'tertiary-30': $tertiary-30,
  'tertiary-40': $tertiary-40,
  'tertiary-50': $tertiary-50,
  'tertiary-60': $tertiary-60,
  'tertiary-70': $tertiary-70,
  'tertiary-80': $tertiary-80,
  'tertiary-90': $tertiary-90,
  'tertiary-95': $tertiary-95,
  'tertiary-99': $tertiary-99,
  'tertiary-on-high': $tertiary-on-high,
  'tertiary-on-medium': $tertiary-on-medium,
  'tertiary-on-disabled': $tertiary-on-disabled,
) !default;

/*-------------------------
  common color
-------------------------*/
$red: #da4453 !default;
$orange: #e9573f !default;
$yellow: #fff350 !default;
$green: #8cc152 !default;
$blue: #4a89dc !default;
$pink: #f16f77 !default;
$purple: #967adc !default;
$cyan: #4fc1e9 !default;
$gray: #bdbdbd !default;
$black: #000 !default;
$white: #fff !default;

$gradation: linear-gradient(90deg, $secondary-95, $primary-95) !default;
$gradation-heading: linear-gradient(to right, $primary-95, #f5fafc) !default;

$light-gray: lighten($gray, 25%);
$light-green: lighten($green, 42%);
$light-pink: lighten($pink, 33%);

$commons-colors: (
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'blue': $blue,
  'pink': $pink,
  'purple': $purple,
  'cyan': $cyan,
  'gray': $gray,
  'black': $black,
  'white': $white,
  'light-gray': $light-gray,
  'light-green': $light-green,
  'light-pink': $light-pink,
) !default;

$gray-10: darken($gray, 40%) !default;
$gray-20: darken($gray, 30%) !default;
$gray-30: darken($gray, 20%) !default;
$gray-40: darken($gray, 10%) !default;
$gray-50: $gray !default;
$gray-60: lighten($gray, 5%) !default;
$gray-70: lighten($gray, 10%) !default;
$gray-80: lighten($gray, 15%) !default;
$gray-90: lighten($gray, 20%) !default;
$gray-100: lighten($gray, 23%) !default;
$gray-dark: rgba(51, 51, 51, 0.7) !default;
$gray-container: #f8f8f8 !default;
$gray-sub: #6e6e6e !default;

$grays: (
  'gray-10': $gray-10,
  'gray-20': $gray-20,
  'gray-30': $gray-30,
  'gray-40': $gray-40,
  'gray-50': $gray-50,
  'gray-60': $gray-60,
  'gray-70': $gray-70,
  'gray-80': $gray-80,
  'gray-90': $gray-90,
  'gray-100': $gray-100,
  'gray-dark': $gray-dark,
  'gray-container': $gray-container,
  'gray-sub': $gray-sub,
) !default;

// カラーの配列を作る
//
// グレースケールと汎用カラーの配列
$basic-colors: map-merge($grays, $commons-colors);

// テーマカラーを含めたすべての配列
$all-colors: map-merge($theme-colors, $basic-colors);
