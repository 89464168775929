@charset 'UTF-8';

@use '../global/' as *;

.copyright-wrapper {
  width: 100%;
  padding-top: 3rem;
  border-top: 1px solid $base-10;
  z-index: $z-index-footer;
  @include media(lg) {
    padding-top: 4.6rem;
  }
}

.copyright {
  color: $base-50;
  font-size: 1rem;
}
