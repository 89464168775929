@charset 'UTF-8';

@use '../global/' as *;

ul.list,
ol.list {
  margin-left: 0.2em;
  li {
    margin-bottom: 0.5em;
  }

  &.li-mb {
    li:nth-of-type(n + 2) {
      margin-top: 0.8em;
    }
    ul,
    ol {
      margin-top: 0.8em;
    }
  }

  // インデント
  &.indent {
    margin-left: 0.5em;
    ul,
    ol {
      margin-top: 0.8em;
    }
  }
}

ul.list,
ul.list-row {
  // dotted
  &.disc {
    list-style: disc outside;
    padding-left: 1.125em;
    ul {
      list-style: circle outside;
    }
  }
  // 四角 & 丸
  &.dot,
  &.square,
  &.circle,
  &.circle-white,
  &.kome,
  &.checkbox,
  &.circle-double {
    & > li {
      line-height: inherit;
      padding-left: 1.4em;
      position: relative;
      &:before {
        content: '';
        display: inline-block;
        font-family: sans-serif;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &.dot {
    & > li {
      &::before {
        width: 5px;
        height: 5px;
        background: $secondary;
        border-radius: 5px;
        position: absolute;
        left: 0;
        top: 12px;
        content: '';
      }
    }
  }

  &.square {
    & > li {
      &:before {
        content: '■';
      }
    }
  }

  &.circle {
    & > li {
      &:before {
        content: '●';
      }
    }
  }
  &.circle-white {
    & > li {
      &:before {
        content: '○';
      }
    }
  }
  &.kome {
    & > li {
      &:before {
        content: '※';
      }
    }
  }
  &.checkbox {
    & > li {
      &:before {
        content: $checkbox-checked;
        font-family: $icon-font-family;
      }
    }
  }
  &.circle-double {
    & > li {
      &:before {
        content: '';
        width: 1em;
        height: 1em;
        margin-top: 0.5em;
        border: 4px solid $primary;
        border-radius: 50%;
      }
    }
  }
}

ol.list,
ol.list-row {
  li {
    margin-left: 0.5em;
  }
  // 数字
  &.number {
    list-style: decimal outside;
    padding-left: 1.125em;
  }
  // (数字)
  &.brackets {
    list-style: decimal outside;
    li {
      list-style-type: none;
      counter-increment: cnt;
      padding-left: 1.5em;
      text-indent: -1.5em;
      &::before {
        content: '(' counter(cnt) ') ';
      }
    }
  }
}

dl.list {
  dt {
    font-weight: bold;
    &:nth-of-type(n + 2) {
      margin-top: 1em;
    }
  }
}

// list の横並び
//
.list-row {
  display: inline-flex;
  flex-flow: column nowrap;
  @include media(md) {
    flex-flow: row wrap;
  }
  &--center {
    width: 100%;
    align-items: center;
  }
  li {
    &:not(:last-child) {
      @include media(md) {
        margin-right: 2rem;
      }
    }
    & + li {
      margin-top: 2rem;
      @include media(md) {
        margin-top: 0;
      }
    }
  }
}

//定義リスト
dl.dl-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  dt {
    font-weight: bold;
    display: block;
    padding-bottom: 1rem;
    &:nth-of-type(n + 2) {
      margin-top: 2rem;
    }
    @include media(md) {
      border-bottom: 1px solid $border-color;
      padding-bottom: 2.5rem;
      width: 25%;
    }
    @include media(xl) {
      width: 20%;
    }
  }
  dd {
    border-bottom: 1px solid $border-color;
    padding-bottom: 2rem;
    width: 100%;
    @include media(md) {
      width: 75%;
      padding-left: 2%;
      padding-bottom: 2.5rem;
      &:nth-of-type(n + 2) {
        margin-top: 2.5rem;
      }
    }
    @include media(xl) {
      width: 80%;
    }
  }

  //定義リストdt 1-20em
  @for $i from 1 through 20 {
    &.dl-list-#{$i} {
      dt {
        @include media(md) {
          width: #{$i}em;
        }
      }
      dd {
        @include media(md) {
          width: calc(100% - #{$i}em);
          padding-left: 2%;
          padding-bottom: 2.5rem;
        }
      }
    }
  }
}

// フロー
.flow {
  margin: 4rem auto 0;
  @include media(lg) {
    margin-top: 4.2rem;
  }
  .flow-item {
    position: relative;
    padding: 2rem;
    border: 1px solid $base;
    border-radius: $base-border-radius;
    @include media(lg) {
      padding: 2.8rem;
    }
    & + li {
      margin-top: 4rem;
      position: relative;
      &::before {
        content: $flow-arrow;
        font-family: $icon-font-family;
        font-size: 2rem;
        position: absolute;
        top: -35px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &:nth-of-type(n) {
      .flow-container {
        &::before,
        &::after {
          width: 4rem;
          height: 4rem;
          position: absolute;
          top: 20px;
          left: 20px;
          text-align: center;
          font-weight: normal;
          font-family: $base-font-family-c;
          @include media(lg) {
            width: 54px;
            height: 54px;
          }
        }
        &::before {
          content: 'STEP';
          font-size: 1rem;
          background-color: $primary;
          border-radius: 100%;
          padding: 0.4rem 0 0;
          @include media(lg) {
            padding: 0.8rem 0 0;
          }
        }
        &::after {
          content: '';
          font-size: 1.8rem;
          padding: 1.6rem 0 0;
          line-height: 1;
          @include media(lg) {
            font-size: 2.4rem;
            padding: 2.2rem 0 0;
          }
        }
      }
    }
    &:nth-of-type(1) {
      .flow-container {
        &::after {
          content: '1';
        }
      }
    }
    &:nth-of-type(2) {
      .flow-container {
        &::after {
          content: '2';
        }
      }
    }
    &:nth-of-type(3) {
      .flow-container {
        &::after {
          content: '3';
        }
      }
    }
    &:nth-of-type(4) {
      .flow-container {
        &::after {
          content: '4';
        }
      }
    }
    &:nth-of-type(5) {
      .flow-container {
        &::after {
          content: '5';
        }
      }
    }
    &:nth-of-type(6) {
      .flow-container {
        &::after {
          content: '6';
        }
      }
    }
    &:nth-of-type(7) {
      .flow-container {
        &::after {
          content: '7';
        }
      }
    }
    &:nth-of-type(8) {
      .flow-container {
        &::after {
          content: '8';
        }
      }
    }
    &:nth-of-type(9) {
      .flow-container {
        &::after {
          content: '9';
        }
      }
    }
    &:nth-of-type(10) {
      .flow-container {
        &::after {
          content: '10';
        }
      }
    }
  }
  .flow-container {
    padding-left: 5.2rem;
    @include media(lg) {
      padding-left: 6.6rem;
      display: flex;
      gap: 2rem;
    }
  }
  .flow-picture {
    display: none;
    @include media(lg) {
      width: 14rem;
      display: block;
      border-radius: 16px;
      overflow: hidden;
    }
  }
  .flow-contents {
    @include media(lg) {
      flex: 1;
    }
    &__title {
      margin: 1rem auto 1.6rem;
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1.5;
      @include media(lg) {
        margin: 0.8rem auto 1.6rem;
        font-size: 2rem;
      }
    }
    &__text {
      font-size: 1.4rem;
      @include media(lg) {
        font-size: 1.6rem;
      }
    }
  }
}
