@charset 'UTF-8';

@use '../global/' as *;

// variable
$accordion-open-class-name: '.active';

.accordion-wrapper {
  width: 100%;
}

.accordion {
  position: relative;
  border-top: 1px solid $base-20;
  transition: background-color $base-transition;
  &:last-child {
    border-bottom: 1px solid $base-20;
  }
  @media (hover: hover) {
    &:hover {
      opacity: 1;
      background-color: $primary-container;
    }
  }
}

.accordion-heading {
  width: 100%;
  margin-bottom: 0;
  padding: 1.6rem 0.8rem;
  font-weight: bold;
  color: $base;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.5;
  font-size: 1.6rem;
  cursor: pointer;
  @include media(lg) {
    font-size: 1.8rem;
    padding: 2.4rem 0.8rem;
  }
  .accordion-icon {
    width: 2.4rem;
    min-width: 2.4rem;
    height: 2.4rem;
    border: 1px solid $border-color;
    border-radius: 50%;
    position: relative;
    transition: transform 0.3s;
    box-shadow: 0 3.6px 5.4px rgb(0 0 0 / 5%);
    background: $white;
    // arrow
    &::before,
    &::after {
      width: 2px;
      height: 10px;
      position: absolute;
      left: calc(50% - 1px);
      top: calc(50% - 5px);
      background-color: $base;
      transition: all $base-transition;
      content: '';
    }
    &::before {
      transform: rotate(90deg);
    }
  }
  &#{$accordion-open-class-name} {
    .accordion-icon {
      filter: drop-shadow(0 3.6px 5.4px rgba(0, 0, 0, 0.12));
      &::before {
        transform: rotate(450deg);
      }
      &::after {
        transform: rotate(270deg);
      }
    }
  }
}

.accordion-contents {
  overflow: hidden;
  max-height: 0;
  transition: max-height $base-transition;
}

.accordion-contents-inner {
  margin: 0 0.8rem;
  padding: 0.4rem 0 3.5rem;
  font-size: 1.4rem;
  @include media(lg) {
    padding-top: 0;
    font-size: 1.5rem;
  }
}
