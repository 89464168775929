@charset 'UTF-8';
@use './global/' as sys;
@use '../var/' as g;

.schedule-list-wrapper {
  position: relative;
  @include sys.makeContainer;
  @include sys.media(lg) {
    padding: 0;
  }
}

.schedule-wrapper {
  position: relative;
  & + & {
    margin-top: 4rem;
  }
}

.schedule-section-name {
  border-top: 1px solid g.$gray-70;
  border-bottom: 1px solid g.$gray-70;
  font-weight: bold;
  margin-bottom: 1em;
  padding: 0.5em 1em;
}

.section-schedule-wrapper {
  position: relative;
}

table.section-schedule-table {
  width: 100%;
  table-layout: fixed;
  text-align: center;
  font-size: 1.5rem;
  th,
  td {
    padding: 1.4rem 2rem;
    border: 1px solid g.$table-border-color;
  }
  th {
    width: 20%;
    font-weight: normal;
  }
  thead {
    th,
    td {
      color: g.$white;
      background-color: g.$secondary;
    }
  }

  tbody {
    th,
    td {
      &:nth-of-type(n + 2) {
        @include sys.media(md) {
          width: 15%;
        }
      }
    }

    th {
      background-color: g.$table-tbody-th-background;
      padding: 1em;
    }

    td {
      background-color: g.$table-td-background-color;
      overflow-wrap: break-word;
      padding: 1em;
    }
  }

  .time-zone_1,
  .time-zone_2,
  .time-zone_3 {
    background-color: g.$table-tbody-th-background;
    font-weight: bold;
    font-size: 0.9375em;
    white-space: nowrap;
  }

  .reserve {
    background-color: g.$gray-70;
    color: g.$white;
    font-size: 0.75em;
    font-weight: bold;
    display: inline-block;
    padding: 0.375em 0.5em;
    white-space: nowrap;
    display: inline;
    border-radius: 3px;
    &.type_1 {
      background-color: g.$primary;
      display: none;
    }
    &.type_2 {
      background-color: g.$secondary;
    }
    &.type_3 {
      background-color: g.$white;
      border: 1px solid g.$secondary;
      color: g.$secondary;
    }
  }

  .doctor-container {
    white-space: normal;
    & + .doctor-container {
      margin-top: 1em;
    }
  }

  .doctor-name {
    margin-top: 0.25em;
    white-space: nowrap;
    // 男性医師名のカラー
    &.doctor-name-male,
    &.doctor-name-male a {
      color: g.$gray-dark;
    }
    // 女性医師名のカラー
    &.doctor-name-female,
    &.doctor-name-female a {
      color: g.$pink;
    }
    .doctor-referral {
      &.disabled {
        pointer-events: none;
      }
    }
  }

  .comment,
  .comment-sub {
    font-size: 1.3rem;
  }
}

.update-at-schedule {
  font-size: 0.875em;
  margin-bottom: 0.5em;
  text-align: right;
}

.schedule-lower-comment {
  margin-top: 1em;
}

.lower-comment {
  font-size: 0.875em;
  & + & {
    margin-top: 0.5em;
  }
}

.btn-section-link {
  background-color: g.$primary;
  border: 1px solid g.$primary;
  border-radius: g.$base-border-radius;
  color: g.$white;
  display: inline-flex;
  align-items: center;
  padding: 0.6em 1em 0.6em 1.5em;
  text-decoration: none;
  &::after {
    content: g.$arrow-right;
    font-family: g.$icon-font-family;
    color: g.$white;
    margin-left: 1em;
  }
  @media (hover: hover) {
    &:hover {
      background: g.$white;
      color: g.$primary;
      &::after {
        color: g.$primary;
        right: 0.6em;
      }
    }
  }
}
