@charset 'UTF-8';

@use './form' as *;

// display: none; からのフェードイン
//
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes transY {
  0% {
    margin-top: -1.6rem;
  }
  100% {
    margin-top: 0;
  }
}

@keyframes ripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 0px 0px 0px $form-radio-ripple-size rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0px 0px 0px $form-radio-ripple-size rgba(0, 0, 0, 0);
  }
}

@keyframes rippleRev {
  0% {
    box-shadow: 0px 0px 0px $form-radio-ripple-size rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 0px 0px 0px $form-radio-ripple-size rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }
}
