@charset 'UTF-8';

@use '../global/' as *;

.header-wrapper {
  width: 100%;
  padding: 1.5rem;
  position: absolute;
  @include media(md) {
    padding: 3rem 3rem 0;
  }
  @include media(lg) {
    padding: 4rem 13.4rem 0 4rem;
  }
}

.header-inner {
  @each $key, $value in $header-height {
    @include media($key, true) {
      height: $value !important;
    }
  }
  @include media(lg) {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}

.header-inner-left {
  @include media(lg) {
    flex: 1 0;
  }
}

.header-inner-center {
  display: none;
  @include media(xl) {
    display: flex;
    justify-content: center;
    flex: 1 1 0;
  }
}

.header-inner-right {
  @include media(lg) {
    display: flex;
    justify-content: flex-end;
    flex: 1 1 0;
    .header-menu-list {
      font-size: 1.4rem;
      gap: 1.8rem;
    }
  }
}

.header-logo {
  max-width: 217px;
  @include media(lg) {
    max-width: none;
  }
  a {
    vertical-align: middle;
  }
}

.header-menu-list {
  display: none;
  @include media(xl) {
    display: flex;
    gap: 2.8rem;
    white-space: nowrap;
  }
  a {
    color: $white;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background: $white;
      bottom: -1rem;
      opacity: 0;
      visibility: hidden;
      transition: 0.5s;
    }
    @media (hover: hover) {
      &:hover {
        opacity: 1;
        &::after {
          visibility: visible;
          bottom: 0;
          opacity: 1;
        }
      }
    }
  }
}

.search-box {
  width: 100%;
  margin-bottom: 2.4rem;
  padding: 1.6rem;
  border: 1px solid $base-80;
  border-radius: 3rem;
  line-height: 1;
  text-align: left;
  @include media(lg) {
    margin: 3.2rem 0;
  }
  input[type='text'] {
    width: 100%;
  }
}
.search-box-inner {
  display: flex;
  align-items: center;
  .icon {
    font-size: 1.8rem;
    position: relative;
    top: -1px;
    @include media(lg) {
      font-size: 1.9rem;
      top: 0;
    }
  }
}
